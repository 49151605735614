.react-grid-item.react-draggable-dragging {
  opacity: 0.5;
  cursor: grabbing;
}

.react-grid-item.react-grid-placeholder {
  background-color: var(--chakra-colors-brand-primary);
  opacity: 0.5;
}

.react-grid-item {
  cursor: grab;
}

.react-grid-item.react-draggable-dragging:hover .options-block,
.react-grid-item.resizing:hover .options-block {
  opacity: 0;
}

.options-block {
  opacity: 0;
  transition: all 0.2s ease-out;
}

.react-grid-item:hover .options-block {
  opacity: 1;
}

.react-grid-item > .react-resizable-handle::after {
  border-right: 2px solid rgba(0, 0, 0, 0);
  border-bottom: 2px solid rgba(0, 0, 0, 0);
}

.react-resizable-handle {
  background: transparent;
}
