.container,
.container div,
.container p,
.container div a {
  transition: all 0.3s linear;
}

.container div {
  width: 7rem;
}

.container p {
  position: absolute;
  display: block;
  opacity: 0;
  left: 4rem;
  font-weight: bolder;
}

.isExtended div {
  width: 14rem;
}
.isExtended div a {
  transform: translateX(-3rem);
}
.isExtended p {
  opacity: 1;
  display: block;
}
